import styled from "styled-components"

const StyledPost = styled.section`
  overflow: hidden;
  p,
  a,
  li {
    font-size: 18px;
    line-height: 26px;
  }
  .post {
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px 100px;

      @media (max-width: 1199px) {
        margin: 0 -15px 30px;
      }
    }

    &__col {
      padding: 0 15px;

      &.col-3 {
        flex: 0 0 25%;
        max-width: 25%;

        @media (max-width: 575px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      &.col-6 {
        flex: 0 0 50%;
        max-width: 50%;

        @media (max-width: 1199px) {
          flex: 0 0 75%;
          max-width: 75%;
        }

        @media (max-width: 575px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

    &__author {
      &-image {
        width: 55px;
        height: 55px;
        border: 5px solid #00fb6e;
        border-radius: 50%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }

      &-position {
        display: flex;
        text-transform: uppercase;
        font-size: 12px;
        margin: 20px 0 0 0;
      }
    }

    &__date {
      display: flex;
      position: relative;
      padding: 0 0 0 20px;
      margin: 40px 0 50px;

      @media (max-width: 575px) {
        margin: 15px 0;
      }

      &:before {
        content: "";
        width: 14px;
        height: 13px;
        position: absolute;
        top: 3px;
        left: 0;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iSWNvbl9pb25pYy1pb3MtY2FsZW5kYXIiIGRhdGEtbmFtZT0iSWNvbiBpb25pYy1pb3MtY2FsZW5kYXIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE0IiBoZWlnaHQ9IjEyLjkyMyIgdmlld0JveD0iMCAwIDE0IDEyLjkyMyI+CiAgPHBhdGggaWQ9IlBhdGhfMzkxIiBkYXRhLW5hbWU9IlBhdGggMzkxIiBkPSJNMTYuMDI5LDYuNzVIMTQuNjgzdi44MDhhLjI3LjI3LDAsMCwxLS4yNjkuMjY5aC0uNTM4YS4yNy4yNywwLDAsMS0uMjY5LS4yNjlWNi43NUg3LjE0NHYuODA4YS4yNy4yNywwLDAsMS0uMjY5LjI2OUg2LjMzN2EuMjcuMjcsMCwwLDEtLjI2OS0uMjY5VjYuNzVINC43MjFBMS4zNSwxLjM1LDAsMCwwLDMuMzc1LDguMVYxNy4yNUExLjM1LDEuMzUsMCwwLDAsNC43MjEsMTguNkgxNi4wMjlhMS4zNSwxLjM1LDAsMCwwLDEuMzQ2LTEuMzQ2VjguMUExLjM1LDEuMzUsMCwwLDAsMTYuMDI5LDYuNzVabS4yNjksMTAuMWEuNjc1LjY3NSwwLDAsMS0uNjczLjY3M0g1LjEyNWEuNjc1LjY3NSwwLDAsMS0uNjczLS42NzNWMTAuNzg4YS4yNy4yNywwLDAsMSwuMjY5LS4yNjlIMTYuMDI5YS4yNy4yNywwLDAsMSwuMjY5LjI2OVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zLjM3NSAtNS42NzMpIiBmaWxsPSIjZmZmIi8+CiAgPHBhdGggaWQ9IlBhdGhfMzkyIiBkYXRhLW5hbWU9IlBhdGggMzkyIiBkPSJNMTAuMDc3LDQuNzY5QS4yNy4yNywwLDAsMCw5LjgwOCw0LjVIOS4yNjlBLjI3LjI3LDAsMCwwLDksNC43Njl2LjgwOGgxLjA3N1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjMwOCAtNC41KSIgZmlsbD0iI2ZmZiIvPgogIDxwYXRoIGlkPSJQYXRoXzM5MyIgZGF0YS1uYW1lPSJQYXRoIDM5MyIgZD0iTTI1LjgyNyw0Ljc2OWEuMjcuMjcsMCwwLDAtLjI2OS0uMjY5aC0uNTM4YS4yNy4yNywwLDAsMC0uMjY5LjI2OXYuODA4aDEuMDc3WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE0LjUxOSAtNC41KSIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        filter: invert(100%);
      }
    }

    &__share {
      display: flex;
      flex-direction: column;

      @media (max-width: 575px) {
        flex-direction: row;
        margin: 0 0 30px 0;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border: 1px solid #000 !important;
        border-radius: 50%;
        transition: all 150ms linear;
        margin: 0 0 15px 0;

        @media (max-width: 575px) {
          margin: 0 15px 0 0;
        }

        svg {
          max-height: 12px;
        }

        &:hover {
          background-color: #00fb6e !important;
        }
      }
    }

    &__excerpt {
      font-size: 34px;
      font-weight: 700;
      margin: 0 0 50px 0;

      a {
        text-decoration: underline;
      }

      span {
        color: #00fb6e;
      }

      @media (max-width: 575px) {
        font-size: 24px;
        margin: 0 0 30px 0;
      }
    }

    &__cta {
      margin: 0 0 50px 0;
    }

    &__content {
      h1,
      h2,
      h3,
      h4,
      h5,
      h5,
      ul,
      ol,
      p {
        &:not(:last-child) {
          margin: 0 0 25px 0;

          @media (max-width: 575px) {
            margin: 0 0 15px 0;
          }
        }
      }
      ol {
        margin-left: 16px;
        li {
          list-style-type: decimal;
          margin-bottom: 10px;
          a {
            text-decoration: underline;
            transition: 0.2s;
            &:hover {
              color: #00fb6e;
            }
          }
        }
      }
      ul {
        list-style-type: disc;
      }
      ul,
      ol {
        padding-left: 20px;
        li {
          margin-bottom: 10px;
          a {
            text-decoration: underline;
            transition: 0.2s;
            &:hover {
              color: #00fb6e;
            }
          }
        }
      }
      p {
        a {
          text-decoration: underline;
          transition: 0.2s;
          &:hover {
            color: #00fb6e;
          }
        }
      }
      img {
        width: auto;
        max-width: 100%;
      }
      .gatsby-image-wrapper,
      .custom-image-wrapper {
        &.aligncenter {
          text-align: center;
        }
        &.alignleft {
          float: left;
          margin: 10px;
          &::after {
            content: "";
            display: block;
            clear: both;
          }
        }
        &:not(.no-full-image) {
          margin: 55px auto;
          position: relative;
          width: calc(200% + 30px);
          left: calc(-50% - 15px);
          @media (max-width: 1199px) {
            margin: 15px auto;
            width: 100%;
            left: 0;
          }
        }
      }
      .custom-image-wrapper {
        img {
          height: auto;
          max-width: 100%;
        }
      }
      .text-center {
        text-align: center;
      }
      .btn-link {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 3px solid #000;
        margin: 0 0 30px;
        background-color: transparent;
        text-align: center;
        padding: 14px 20px;
        min-width: 220px;
        transition: 0.2s;
        position: relative;
        z-index: 1;
        overflow: hidden;
        cursor: pointer;
        z-index: 2;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        color: #000;
        transition: 0.2s;
        font-family: futura-pt-bold, sans-serif;
        font-weight: 700;
        &::after {
          content: "";
          z-index: -1;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transition: 0.2s;
          transform: translateY(110%);
          background-color: #00fb6e;
        }
        &:hover {
          border-color: #00fb6e !important;
          color: #000 !important;
          svg {
            [stroke] {
              stroke: #000;
            }
          }
          &::after {
            transform: translateY(0);
          }
        }
      }
    }
  }
`

export default StyledPost
